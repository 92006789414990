import axios from "axios";
import { EXECUTE_RESULT } from "@/constant/baseConstant";

export default {

  // Base request
  baseRequest: function (request, data, route) {
    if (route === undefined || route === null) route = '';
    return new Promise(function (resolve, reject) {
      axios({
        method: request.method,
        url: request.hostUrl + request.prefixedRoute + request.route + route,
        data: data,
        withCredentials: true,
      })
        .then(res => {
          try {
            let resTemp = res.toJSON();
            if (resTemp.name === 'Error') {
              reject(resTemp);
            }
          } catch {
            let response = res.data;
            if (response.result === EXECUTE_RESULT.SUCCESS) {
              resolve(response);
            } else {
              reject(response);
            }
          }
        })
        .catch(error => {
          reject(error);
        });
    })
  },
}