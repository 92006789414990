import baseComponent from "@/scripts/baseComponent";

export default {

    extends: baseComponent,

    props: {
        element: {
            type: Object,
            default: {}
        },
        index: {
            type: String,
            default: null
        },
        info: {
            type: String,
            default: null
        },
    },

    data() {
        return {

        };
    },

    computed: {

    },

    created() {

    },

    mounted() {

    },

    methods: {

    }
} 