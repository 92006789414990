// Constant
import { API_CALL_STATUS } from "@/constant/config";
import { POPUP_ID } from "@/constant/baseConstant";
import { SETTINGS_SYSTEM_MANAGEMENT_API } from "@/constant/apiSettings";

// Extend
import base from '@/scripts/base';

// Api
import api from '@/api/baseApi';

// Component 
import Decentralized from "@/views/components/systemManager/Decentralized.vue";

// Function 
const queryString = require('query-string');

export default {

  components: {
    Decentralized,
  },

  extends: base,

  data() {
    return {
      options: [
        {
          label: 'Khai thác tiền ảo',
          value: 1,
        },
        {
          label: 'Thanh toán có dấu hiệu bất thường',
          value: 2,
        },
        {
          label: 'Tạo nhiều tài khoản nhằm hưởng lợi từ các chương trình khuyến mại',
          value: 3,
        },
        {
          label: 'Nguồn đăng ký có vấn đề',
          value: 4,
        },
        {
          label: 'Tuỳ chọn',
          value: 0,
        }
      ],
      value: 1,

      functionalGroups: [],
      decentralized: [],
    };
  },

  props: {
    elements: {
      type: Array,
      default: []
    },
  },

  computed: {
  },

  created() {

  },

  mounted() {
  },

  watch: {
    elements: function (newVal, oldVal) {
      if (newVal.length === 1) {
        this.getData();
      } else {
        /* empty */
      }
    }
  },

  methods: {
    //#region Close this pop-up
    closeThisPopup() {
      this.closePopup(POPUP_ID.RESTRICTED_IP_MODIFY);
    },
    //#endregion

    // Get data
    getData() {
      this.feedStatusLoading(API_CALL_STATUS.LOADING);
      let data = {
        ip: this.elements[0].ip
      }
      api.baseRequest(SETTINGS_SYSTEM_MANAGEMENT_API.RESTRICTED_IP_DETAILS, null, `?${queryString.stringify(data)}`)
        .then((res) => {
          try {
            let responseData = res.data;
            this.functionalGroups = responseData.functionalGroups;
            this.decentralized = responseData.decentralized;
            this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
          } catch (error) {
            console.log("getData -> error", error)
            this.feedStatusLoading(API_CALL_STATUS.FAILED);
          }
        })
        .catch(error => {
          this.processCatchApiRequest(error, "Request failed ...", 5)
        });
    },
  },
}