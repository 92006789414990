// Restricted email doamin
export const RESTRICTED_EMAIL_DOMAIN_CREATE_STATUS = {
    INSERT_SUCCESS: 1,
    ACTIVATED_SUCCESS: 2,
    DOMAIN_ALREADY_EXISTS: 3,
}

export const RESTRICTED_EMAIL_DOMAIN_MODIFY_TYPE = {
    ACTIVATE: 1,
    DEACTIVATE: 2,
    INFORMATION: 3,
}

// Restricted software
export const RESTRICTED_SOFTWARE_CREATE_STATUS = {
    INSERT_SUCCESS: 1,
    ACTIVATED_SUCCESS: 2,
    SOFTWARE_ALREADY_EXISTS: 3,
}

export const RESTRICTED_SOFTWARE_MODIFY_TYPE = {
    ACTIVATE: 1,
    DEACTIVATE: 2,
    INFORMATION: 3,
}

// Restricted software
export const RESTRICTED_IP_CREATE_STATUS = {
    INSERT_SUCCESS: 1,
    ACTIVATED_SUCCESS: 2,
    SOFTWARE_ALREADY_EXISTS: 3,
}

export const RESTRICTED_IP_MODIFY_TYPE = {
    ACTIVATE: 1,
    DEACTIVATE: 2,
}

// Restricted software
export const EXCLUDED_IP_CREATE_STATUS = {
    INSERT_SUCCESS: 1,
    ACTIVATED_SUCCESS: 2,
    IP_ALREADY_EXISTS: 3,
}

export const EXCLUDED_IP_MODIFY_TYPE = {
    ACTIVATE: 1,
    DEACTIVATE: 2,
    INFORMATION: 3,
}

// Admin functions
export const ADMIN_FUNCTIONS_CREATE_STATUS = {
    INSERT_SUCCESS: 1,
    ACTIVATED_SUCCESS: 2,
    ID_ALREADY_EXISTS: 3,
    CODE_ALREADY_EXISTS: 4,
}

export const ADMIN_FUNCTIONS_MODIFY_TYPE = {
    ACTIVATE: 1,
    DEACTIVATE: 2,
    INFORMATION: 3,
}

// Role management
export const ROLE_MANAGEMENT_MODIFY_TYPE = {
    STAFF: 1,
    DECENTRALIZED: 2,
}