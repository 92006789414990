// Host url
export const HOST_URL = {
  CLIENT_URL: process.env.VUE_APP_CLIENT_HOST,
  API_URL: process.env.VUE_APP_API_HOST,
};

// Prefixed
export const PRE_FIX_ROUTE = {
  SYSTEM_MANAGEMENT: 'api/system-manager/',
  RATING: 'api/rating/',
}

// System management
export const SETTINGS_SYSTEM_MANAGEMENT_ROUTE = {
  RESTRICTED_IP_VIEW: 'restricted-ip-view',
  RESTRICTED_IP_CREATE: 'restricted-ip-create',
  RESTRICTED_IP_MODIFY: 'restricted-ip-modify',
  RESTRICTED_IP_DELETE: 'restricted-ip-delete',
  RESTRICTED_IP_DETAILS: 'restricted-ip-details',



  BLACKLIST_RULE_VIEW: 'blacklist-rule-view',
  BLACKLIST_RULE_CREATE: 'blacklist-rule-create',
  BLACKLIST_RULE_MODIFY: 'blacklist-rule-modify',
  BLACKLIST_RULE_DELETE: 'blacklist-rule-delete',
  BLACKLIST_RULE_DETAILS: 'blacklist-rule-details',
}

// Rating
export const SETTINGS_RATING_ROUTE = {
    RATING_VIEW: 'rating-view',
    RATING_ACCEPT: 'rating-update-accepted',
    RATING_CLOSE: 'rating-update-close',
    RATING_UPDATE_STATUS: 'rating-update-status',
    RATING_RESPONSE_VIEW: 'rating-response-view',
    RATING_RESPONSE_SUBMIT: 'rating-response-submit',
    RATING_INTERNAL_RESPONSE_VIEW: 'rating-internal-response-view',
    RATING_INTERNAL_RESPONSE_SUBMIT: 'rating-internal-response-submit',
    RATING_INTERNAL_RESPONSE_MARKREAD: 'rating-internal-response-markread',
}