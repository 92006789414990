import {
    HOST_URL,
    PRE_FIX_ROUTE,
    SETTINGS_SYSTEM_MANAGEMENT_ROUTE,
    SETTINGS_RATING_ROUTE
} from "@/constant/routeApi";

//#region System manager
export const SETTINGS_SYSTEM_MANAGEMENT_API = {

    //#region Restricted IP

    // Restricted IP ___ View
    RESTRICTED_IP_VIEW: {
        method: 'GET',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.SYSTEM_MANAGEMENT,
        route: SETTINGS_SYSTEM_MANAGEMENT_ROUTE.RESTRICTED_IP_VIEW
    },

    // Restricted IP ___ Create
    RESTRICTED_IP_CREATE: {
        method: 'POST',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.SYSTEM_MANAGEMENT,
        route: SETTINGS_SYSTEM_MANAGEMENT_ROUTE.RESTRICTED_IP_CREATE
    },

    // Restricted IP ___ Modify
    RESTRICTED_IP_MODIFY: {
        method: 'POST',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.SYSTEM_MANAGEMENT,
        route: SETTINGS_SYSTEM_MANAGEMENT_ROUTE.RESTRICTED_IP_MODIFY
    },

    // Restricted IP ___ Delete
    RESTRICTED_IP_DELETE: {
        method: 'POST',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.SYSTEM_MANAGEMENT,
        route: SETTINGS_SYSTEM_MANAGEMENT_ROUTE.RESTRICTED_IP_DELETE
    },

    // Restricted IP ___ Details
    RESTRICTED_IP_DETAILS: {
        method: 'GET',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.SYSTEM_MANAGEMENT,
        route: SETTINGS_SYSTEM_MANAGEMENT_ROUTE.RESTRICTED_IP_DETAILS
    },

    //#endregion

    //#region Restricted rule

    // Blacklist rule ___ View
    BLACKLIST_RULE_VIEW: {
        method: 'GET',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.SYSTEM_MANAGEMENT,
        route: SETTINGS_SYSTEM_MANAGEMENT_ROUTE.BLACKLIST_RULE_VIEW
    },

    // Blacklist rule ___ Create
    BLACKLIST_RULE_CREATE: {
        method: 'POST',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.SYSTEM_MANAGEMENT,
        route: SETTINGS_SYSTEM_MANAGEMENT_ROUTE.BLACKLIST_RULE_CREATE
    },

    // Blacklist rule ___ Modify
    BLACKLIST_RULE_MODIFY: {
        method: 'POST',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.SYSTEM_MANAGEMENT,
        route: SETTINGS_SYSTEM_MANAGEMENT_ROUTE.BLACKLIST_RULE_MODIFY
    },

    // Blacklist rule ___ Delete
    BLACKLIST_RULE_DELETE: {
        method: 'POST',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.SYSTEM_MANAGEMENT,
        route: SETTINGS_SYSTEM_MANAGEMENT_ROUTE.BLACKLIST_RULE_DELETE
    },

    // Blacklist rule ___ Details
    BLACKLIST_RULE_DETAILS: {
        method: 'GET',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.SYSTEM_MANAGEMENT,
        route: SETTINGS_SYSTEM_MANAGEMENT_ROUTE.BLACKLIST_RULE_DETAILS
    },

    //#endregion

}
//#endregion

//#region Rating
export const SETTINGS_RATING_API = {

    RATING_VIEW: {
        method: 'GET',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.RATING,
        route: SETTINGS_RATING_ROUTE.RATING_VIEW
    },
    RATING_ACCEPT: {
        method: 'POST',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.RATING,
        route: SETTINGS_RATING_ROUTE.RATING_ACCEPT
    },
    RATING_CLOSE: {
        method: 'POST',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.RATING,
        route: SETTINGS_RATING_ROUTE.RATING_CLOSE
    },
    RATING_UPDATE_STATUS: {
        method: 'POST',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.RATING,
        route: SETTINGS_RATING_ROUTE.RATING_UPDATE_STATUS
    },
    RATING_RESPONSE: {
        method: 'POST',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.RATING,
        route: SETTINGS_RATING_ROUTE.RATING_RESPONSE_VIEW
    },
    RATING_RESPONSE_SUBMIT: {
        method: 'POST',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.RATING,
        route: SETTINGS_RATING_ROUTE.RATING_RESPONSE_SUBMIT
    },
    RATING_INTERNAL_RESPONSE_VIEW: {
        method: 'POST',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.RATING,
        route: SETTINGS_RATING_ROUTE.RATING_INTERNAL_RESPONSE_VIEW
    },
    RATING_INTERNAL_RESPONSE_SUBMIT: {
        method: 'POST',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.RATING,
        route: SETTINGS_RATING_ROUTE.RATING_INTERNAL_RESPONSE_SUBMIT
    },
    RATING_INTERNAL_RESPONSE_MARKREAD: {
        method: 'GET',
        hostUrl: HOST_URL.API_URL,
        prefixedRoute: PRE_FIX_ROUTE.RATING,
        route: SETTINGS_RATING_ROUTE.RATING_INTERNAL_RESPONSE_MARKREAD
    },
}
//#endregion